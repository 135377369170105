/* Define CSS variables for frequently used values */
:root {
  --navbar-height: 60px;
  --app-footer-height: 35px;
  --notification-bar-height: 35px;
  --header-menu-height: 63px;
  --img-container-height: 160px;
  --breadcrumb-title-height: 59px; /* Breadcrumb t on large screens */
  
  /* Combined heights for different screen sizes */
  --total-offset-height: 302px; /* navbar + footer + notification + header + img container */
  --total-offset-height-medium: 287px ; /* navbar + footer + notification + header + img container */
  --total-offset-height-small: 290px; /* Reduced for small screens */
}

.pracDashboardSizeNotifications {
  min-height: calc(100vh - var(--total-offset-height));
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
}

.tableContainer {
  height: calc(100vh - var(--total-offset-height) - var(--breadcrumb-title-height));
  width: 100%;
  overflow: hidden;
}

.breadcrumb {
  margin: 1rem;
}

.breadcrumb-item {
  font-size: 1rem;
}

.breadcrumb-item-link {
  font-size: 1rem;
}

/* Responsive design with cleaner media queries */
@media screen and (max-width: 1440px) {
  .breadcrumb {
    margin: 0.75rem;
  }
  
  .breadcrumb-item {
    font-size: 0.75rem;
  }

  .breadcrumb-item-link {
    font-size: 0.85rem;
  }
}

@media screen and (max-width: 1024px) {
  .tableContainer {
    height: calc(100vh - var(--total-offset-height-medium) - var(--breadcrumb-title-height-medium));
  }
}

@media screen and (max-width: 768px) {
  .breadcrumb {
    margin: 0.75rem;
  }
  
  .breadcrumb-item {
    font-size: 0.75rem;
  }
  
  .breadcrumb-item-link {
    font-size: 0.75rem;
  }
  
  .tableContainer {
    height: calc(100vh - var(--total-offset-height-small) - var(--breadcrumb-title-height-small));
  }
  
  .pracDashboardSizeNotifications {
    min-height: calc(100vh - var(--total-offset-height-small));
  }
}

/* DataGrid specific styles to improve rendering */
.notification-data-grid {
  height: 100%;
}

.notification-data-grid .MuiDataGrid-columnsPanel > div:first-child {
  display: none;
}

.notification-data-grid .MuiDataGrid-row {
  background-color: #ffffff;
  border-radius: 1px;
  margin-top: 3px !important;
  margin-bottom: 3px !important;
  margin: 3px;
  border: 0.25px;
  border-color: #E5E5E5;
}

.notification-data-grid .MuiDataGrid-cell {
  border-bottom: none;
}

.notification-data-grid .MuiDataGrid-columnHeadersInner {
  margin: 4px;
}

.notification-data-grid .MuiDataGrid-detailPanelToggleCell {
  padding: 3px;
}

.notification-data-grid .MuiDataGrid-detailPanel {
  margin-left: 4px;
  margin-right: 5px;
  width: 95.2%;
}

.notification-data-grid .MuiDataGrid-columnSeparator--sideRight {
  display: none !important;
}

.notification-data-grid .MuiDataGrid-virtualScroller {
  overflow: auto !important;
}

.filter-button-highlight {
  color: #1976d2;
}

.filter-button {
  color: rgba(0, 0, 0, 0.54);
}

/* OptionDropdownMenu */
.dropdown-menu-container {
  color: #ffffff;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
}

.export-menu-item {
  font-size: 0.875rem;
  padding: 8px 16px;
  cursor: pointer;
}

.export-disabled {
  color: #979797;
  opacity: 0.75;
  cursor: default;
}

.export-enabled {
  color: #000000;
  opacity: 1;
}