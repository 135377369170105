.appFooterNotification {
  display: flex;
  max-height: 122px;
  background-color: #2D3C59;
  padding: 15px 25px;
  bottom: 0;
}

.appFooterNotificationTextSection {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  color: #ffffff;
}

.appFooterNotificationText {
  text-align: left;
  color: #ffffff;
}

.appFooterNotificationVersion {
  margin: 0;
  text-align: left;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .appFooterNotificationSection {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
  }
}